<template>
    <transition name="bounce">
        <div :href="product.url" target="_blank" v-show="show" class="random-product-box"
           :style="{ left: leftShift + '%', top: topShift + '%' }">
            <div class="random-image-box">
                <img v-if="product.images" class="random-product-image" :src="product.images[0].url" alt="">
            </div>
            <div class="random-product-name">
                <strong>{{ productName }}</strong><br><div class="retailer-name">{{ product.supermarket_name }}</div>
            </div>
            <div class="random-product-price">
                &euro;{{ renderPrice(product.price_details[0].price) }}
            </div>
        </div>
    </transition>
</template>
<script>
	export default {
        name: 'RandomProduct',
		props: {
			product: Object,
			index: Number
		},
		data() {
			return {
                show: false,
                timer: null
			}
		},
		created() {
			this.preloadImage()
		},
		computed: {

			leftShift() {
				return Math.floor(Math.random() * 20) + (20 * (this.index % 3))
			},
			topShift() {
				return Math.floor(Math.random() * 35) + (35 * (this.index % 2))
			},
			productName() {
				if (this.product.name && this.product.name.length > 45)
					return this.product.name.substring(0, 43) + '...';
				return this.product.name
			}
		},
		mounted() {
            if (this.product.name)
                this.timer = setTimeout(() => this.setShow(), 3000 * (this.index))
            document.addEventListener('visibilitychange', function() {
                if (document.hidden) {
                    clearTimeout(this.timer)
                    this.hide()
                } else if (!this.timer) {
                     this.timer = setTimeout(() => this.setShow(), 3000 * (this.index))
                }
            })
		},
		methods: {
			renderOldPrice() {
				if (this.product.price_details[1])
					return this.renderPrice(this.product.price_details[1].price)
				return this.renderPrice(this.product.price_details[0].price)
			},
			renderPrice(price) {
				return (Math.round(price * 100) / 100).toFixed(2)
			},
			setShow() {
				this.show = true;
				this.timer = setTimeout(() => this.hide(), 8000);
			},
			hide() {
                this.show = false
			},
			preloadImage() {
				if (this.product.images[0])
					new Image(80, 80).src = this.product.images[0].url;
				return true
			}
		},
	}
</script>
<style>
    .random-product-box {
        font-family: Slack-Lato, appleLogo, sans-serif;
        position: absolute;
        font-size: 12px;
        display: inline-block;
        width: 280px;
        height: 90px;
        border-radius: 5px;
        padding: 5px;
        margin: 5px;
        box-shadow: 1px 1px 2px 2px lightgrey;
        background-color: white;
        color: black;
    }

    .random-image-box {
        float: left;
        height: 100%;
        width: 40%;
    }

    .random-product-image {
        position: relative;
        display: block;
        max-height: 90%;
        max-width: 90%;
        height: auto;
        width: auto;
        margin: auto;
        padding-right: 10px;
        left: 3px;
        top: 5px;
    }

    .retailer-name {
        font-size: 13px;
        margin-top: 5px;
    }

    .random-product-name {
        position: absolute;
        text-align: left;
        padding-right: 10px;
        left: 40%;
        top: 10%;
    }

    .random-product-price {
        font-size: 13px;
        position: absolute;
        top: 75%;
        left: 40%;
    }

    .bounce-leave-active {
        animation: fade-out .2s;
    }

    .bounce-enter-active {
        animation: bounce-in .5s;
    }

    @keyframes fade-out {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }

    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.5);
        }
        100% {
            transform: scale(1);
        }
    }
</style>