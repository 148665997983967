<template>
    <div class="random-product-popup">
        <randomProduct    v-for="(product, index) in products"
                          :key="index"
                          :index="index"
                          :product="product">

        </randomProduct>
    </div>
</template>
<script>
	import randomProduct from './RandomProduct'
	import axios from 'axios';

	export default {
		name: 'RecentCrawlsPopup',
		data() {
			return {
				products: []
			}
		},
		created() {
			this.reloadData()
		},
		methods: {
			reloadData() {
                axios({
                    url: 'https://superscanner.nl/api/v0/products/recentCrawls/',
                    method: 'get',
                    withCredentials: false})
                    .then(response => (this.products = this.products.concat(response.data)))
                    .catch(error => console.log(error));
				setTimeout(() => this.reloadData(), 15000)
			},
		},
		components: {
			randomProduct
		}
	}
</script>
<style>
    .random-product-popup {
        position: absolute;
        display: block;
        line-height: normal;
        height: 100%;
        width: 100%;
    }
</style>
