window.Vue = require('vue').default;

Vue.component(
    'recent-crawl',
    require('./components/RecentCrawlsPopup.vue').default
);

const app = new Vue({
    el: '#app',
});
